var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c("a-table", {
        ref: "table",
        attrs: {
          size: "default",
          bordered: "",
          rowKey: "id",
          columns: _vm.columns,
          pagination: false,
          dataSource: _vm.dataSource,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }